(<template>
  <h3 class="system-log-item__title"
      v-html="logText"></h3>
</template>)

<script>
  export default {
    props: {
      log: {
        type: Object,
        default() {
          return {};
        }
      },
      showDate: Boolean
    },
    data() {
      return {
        progressActive: false,
        domain: this.$getString('domainData', 'platformName')
      };
    },
    computed: {
      // action logs
      actor() { return this.log.actor || {}; },
      adminActor() { return this.log.adminActor || {}; },
      actionLogText() {
        const template = this.getActionLogTemplate(this.logType) || this.$gettext('Uncategorized log');
        const actorName = this.actorIsSystem
          ? this.$gettext('System')
          : this.actor.name || this.$gettext('Unknown user');
        const customerActorName = this.actorIsSystem
          ? this.$gettext('System')
          : this.$isGodmode()
            ? this.actor.name || this.$gettext('Unknown user')
            : this.$gettext('Customer');

        return this.$gettextInterpolate(template, {
          adminActorName: this.$isGodmode()
            ? this.adminActor.name || this.$gettext('Unknown admin')
            : this.$gettextInterpolate(this.$gettext('%{domain}'), this.domain),
          actorName,
          customerActorName,
          initialActorName: this.actor.name || this.$gettext('Unknown user'),
          recipientName: this.recipient.name || this.$gettext('Unknown user'),
          publicId: this.logData.publicId || this.$gettext('Unknown Id'),
          targetLanguage: this.$getLangName(this.logData.targetLanguageId),
        });
      },

      // notification logs
      sender() { return this.log.sender || {}; },
      adminSender() { return this.log.adminSender || {}; },
      notificationLogText() {
        const template = this.getNotificationLogTemplate(this.logType) || this.$gettext('Uncategorized log');

        return this.$gettextInterpolate(template, {
          adminActorName: this.adminSender.name || this.$gettext('Unknown admin'),
          actorName: this.actorIsSystem ? this.$gettext('System') : this.sender.name || this.$gettext('Unknown user'),
          recipientName: this.recipient.name || this.$gettext('Unknown user'),
          publicId: this.logData.publicId || this.$gettext('Unknown Id'),
          targetLanguage: this.$getLangName(this.logData.targetLanguageId),
        });
      },

      // both
      logText() {
        const text = this.isNotificationLog ? this.notificationLogText : this.actionLogText;

        return `${this.showDate ? this.logDate : ''} ${text}`.trim();
      },
      logDate() {
        const momentDate = this.$moment(this.log.createdAt);

        return momentDate.format('DD.MM.YYYY HH:mm');
      },
      actorRole() { return this.log.actorRole || ''; },
      recipient() { return this.log.recipient || {}; },
      isNotificationLog() { return this.log.loggedActionType === 'notification' || this.log.loggedActionType === 'order_notification'; },
      actorIsAdmin() { return this.actorRole === 'admin'; },
      actorIsSystem() { return this.actorRole === 'system'; },
      logType() {
        return this.log.logType || '';
      },
      logData() { return this.log.data || ''; }
    },
    methods: {
      getActionLogTemplate(logType) {
        switch (logType) {
          // interpretation logs types
          case 'job_created':
            return this.actorIsAdmin
              ? this.$gettext('The assignment was created by %{adminActorName}')
              : this.$gettext('The assignment was created by %{actorName}');
          case 'job_updated':
            return this.actorIsAdmin
              ? this.$gettext('The assignment was updated by %{adminActorName}')
              : this.$gettext('The assignment was updated by %{actorName}');
          case 'job_cancelled':
            return this.actorIsAdmin
              ? this.$gettext('The assignment was cancelled by %{adminActorName}')
              : this.$gettext('The assignment was cancelled by %{actorName}');
          case 'job_closed':
            return this.actorIsAdmin
              ? this.$gettext('The assignment was closed by %{adminActorName}')
              : this.$gettext('The assignment was closed by %{actorName}');
          case 'job_in_progress':
            return this.$gettext('The assignment started');
          case 'job_finished':
            return this.actorIsAdmin
              ? this.$gettext('The assignment was finished by %{adminActorName}')
              : this.$gettext('The assignment was finished by %{actorName}');
          case 'job_locked':
            return this.$gettext('The assignment was locked');
          case 'job_updated_expenses':
            return this.actorIsAdmin
              ? this.$gettext('%{adminActorName} updated expenses')
              : this.$gettext('%{actorName} updated expenses');
          case 'application_invited':
            return this.actorIsAdmin
              ? this.$gettext('%{recipientName} was invited by %{adminActorName}')
              : this.$gettext('%{recipientName} was invited by %{actorName}');
          case 'application_applied':
            return this.actorIsAdmin
              ? this.$gettext('%{adminActorName} applied for this assignment on behalf of %{actorName}')
              : this.$gettext('%{actorName} applied for the assignment');
          case 'application_applied_via_standby':
            return this.$gettext('%{actorName} applied for the assignment on behalf of %{initialActorName} via standby');
          case 'application_accepted':
            return this.actorIsAdmin
              ? this.$gettext('%{recipientName} was accepted by %{adminActorName}')
              : this.$gettext('%{recipientName} was accepted by %{customerActorName}');
          case 'application_accepted_via_standby':
            return this.$gettext('%{recipientName} was accepted by %{actorName} via standby');
          case 'application_withdrawn':
            return this.actorIsAdmin
              ? this.$gettext('%{actorName} was withdrawn by %{adminActorName}')
              : this.$gettext('%{actorName} withdrawn from the assignment');
          case 'application_declined':
            return this.actorIsAdmin
              ? this.$gettext('%{adminActorName} declined %{recipientName}\'s offer on behalf of %{actorName}')
              : this.$gettext('%{actorName} declined the offer');
          case 'decline':
            return this.actorIsAdmin
              ? this.$gettext('%{adminActorName} declined %{recipientName}\'s offer on behalf of %{actorName}')
              : this.$gettext('%{actorName} declined the offer');
          case 'application_rejected':
            return this.actorIsAdmin
              ? this.$gettext('%{recipientName} was rejected by %{adminActorName}')
              : this.$gettext('%{recipientName} was rejected by %{actorName}');
          case 'feedback_created':
            return this.actorIsAdmin
              ? this.$gettext('Feedback was created by %{adminActorName}')
              : this.$gettext('Feedback was created by %{actorName}');
          case 'feedback_updated':
            return this.actorIsAdmin
              ? this.$gettext('Feedback was updated by %{adminActorName}')
              : this.$gettext('Feedback was updated by %{actorName}');
          case 'supplier_feedback_updated_job_completed':
            return this.$gettext('Interpreter changed in the feedback how the job finished');
          case 'feedback_confirmed':
            return this.$gettext('Feedback was confirmed');
          case 'supplier_feedback_updated_assignment':
            return this.actorIsAdmin
              ? this.$gettext('%{adminActorName} updated assignment feedback on behalf %{actorName}')
              : this.$gettext('Interpreter %{actorName} updated assignment feedback');
          case 'supplier_feedback_updated_assignment_duration':
            return this.actorIsAdmin
              ? this.$gettext('%{adminActorName} updated assignment duration feedback on behalf %{actorName}')
              : this.$gettext('Interpreter %{actorName} updated assignment duration feedback');
          case 'supplier_feedback_updated_interpreter':
            return this.actorIsAdmin
              ? this.$gettext('%{adminActorName} updated show up feedback on behalf %{actorName}')
              : this.$gettext('Interpreter %{actorName} updated show up feedback');
          case 'demander_feedback_updated_showed_up':
            return this.actorIsAdmin
              ? this.$gettext('%{adminActorName} updated showed up feedback on behalf %{actorName}')
              : this.$gettext('Customer %{actorName} updated showed up feedback');
          case 'demander_feedback_updated_job_completed':
            return this.actorIsAdmin
              ? this.$gettext('%{adminActorName} updated assignment completion feedback on behalf %{actorName}')
              : this.$gettext('Customer %{actorName} updated assignment completion feedback');
          case 'job_statement_created':
            return this.$gettext('System created final job statement record');
          case 'job_statement_updated':
            return this.$gettext('Job statement was updated by %{adminActorName}');
          case 'job_updated_fees_and_order':
            return this.actorIsAdmin
              ? this.$gettext('%{adminActorName} updated fees and booking method')
              : this.$gettext('%{actorName} updated fees and booking method');
          case 'activated_alternative_language':
            return this.$gettext('Alternative language was auto-activated by the System');
          case 'updated_alternative_language':
            return this.$gettext('Alternative language was changed by %{adminActorName} even though the job did not permit it');
          case 'downgraded_qualification':
            return this.$gettext('Qualification was auto-downgraded by the System');
          case 'downgraded_qualification_forcefully':
            return this.$gettext('Qualification was changed by %{adminActorName} even though the job did not permit it');
          case 'updated_gender':
            return this.$gettext('Gender was auto-changed by the System');
          case 'updated_gender_forcefully':
            return this.$gettext('Gender was changed by %{adminActorName} even though the job did not permit it');
          case 'updated_session_type':
            return this.actorIsAdmin
              ? this.$gettext('Session type was auto-changed by the System')
              : this.$gettext('Session type was changed by %{adminActorName}, and not automatically by the System');
          case 'updated_session_type_forcefully':
            return this.$gettext('Session type was changed by %{adminActorName} even though the job did not permit it');
          case 'job_high_demand_flow_activated':
            return this.$gettext('System updated magic auto-invite schedule to fixed interval due to high demand');
          case 'job_updated_references':
            return this.$gettext('Admin updated job references from admin');
          case 'job_pricing_template_swapped':
            return this.actorIsAdmin
              ? this.$gettext('Price templates were updated by %{adminActorName}')
              : this.$gettext('Price templates were updated by %{actorName}');
          case 'job_pricing_template_updated':
            return this.$gettext('Price template was updated from the platform by %{adminActorName}');

          // translation logs types
          case 'order_created':
            return this.actorIsAdmin
              ? this.$gettext('The project was created by %{adminActorName}')
              : this.$gettext('The project was created by %{actorName}');
          case 'order_updated':
            return this.actorIsAdmin
              ? this.$pgettext('translation', 'The project was updated by %{adminActorName}')
              : this.$pgettext('translation', 'The project was updated by %{actorName}');
          case 'order_attachment_downloaded':
            return this.actorIsAdmin
              ? this.$pgettext('translation', 'File was downloaded by %{adminActorName}')
              : this.$pgettext('translation', 'File was downloaded by %{actorName}');
          case 'order_updated_invoicing':
            return this.actorIsAdmin
              ? this.$pgettext('translation', 'The project invoice was updated by %{adminActorName}')
              : this.$pgettext('translation', 'The project invoice was updated by %{actorName}');
          case 'task_created':
            return this.actorIsAdmin
              ? this.$pgettext('translation', 'Project task was created by %{adminActorName}')
              : this.$pgettext('translation', 'Project task was created by %{actorName}');
          case 'task_updated':
            return this.actorIsAdmin
              ? this.$pgettext('translation', 'Project task was updated by %{adminActorName}')
              : this.$pgettext('translation', 'Project task was updated by %{actorName}');
          case 'task_replaced':
            return this.actorIsAdmin
              ? this.$pgettext('translation', 'Project task was replaced by %{adminActorName}')
              : this.$pgettext('translation', 'Project task was replaced by %{actorName}');
          case 'subtask_created':
            return this.actorIsAdmin
              ? this.$pgettext('translation', '%{adminActorName} created subtask [ <b>%{publicId} ] [ %{targetLanguage}<b/> ] ')
              : this.$pgettext('translation', '%{actorName} created subtask [ <b>%{publicId} ] [ %{targetLanguage}<b/> ] ');
          case 'subtask_updated':
            return this.actorIsAdmin
              ? this.$pgettext('translation', '%{adminActorName} updated subtask [ <b>%{publicId} ] [ %{targetLanguage}<b/> ] ')
              : this.$pgettext('translation', '%{actorName} updated subtask [ <b>%{publicId} ] [ %{targetLanguage}<b/> ] ');
          case 'subtask_accepted':
            return this.actorIsAdmin
              ? this.$pgettext('translation', '%{adminActorName} accepted subtask [ <b>%{publicId} ] [ %{targetLanguage}<b/> ] ')
              : this.$pgettext('translation', '%{actorName} accepted subtask [ <b>%{publicId} ] [ %{targetLanguage}<b/> ] ');
          case 'subtask_rejected':
            return this.actorIsAdmin
              ? this.$pgettext('translation', '%{adminActorName} rejected subtask [ <b>%{publicId} ] [ %{targetLanguage}<b/> ] ')
              : this.$pgettext('translation', '%{actorName} rejected subtask [ <b>%{publicId} ] [ %{targetLanguage}<b/> ] ');
          case 'subtask_applied':
            return this.actorIsAdmin
              ? this.$pgettext('translation', '%{adminActorName} applied for this subtask [ <b>%{publicId} ] [ %{targetLanguage}<b/> ] ')
              : this.$pgettext('translation', '%{actorName} applied for this subtask [ <b>%{publicId} ] [ %{targetLanguage}<b/> ] ');
          case 'subtask_review_requested':
            return this.actorIsAdmin
              ? this.$pgettext('translation', '%{adminActorName} requested review for this subtask [ <b>%{publicId} ] [ %{targetLanguage}<b/> ] ')
              : this.$pgettext('translation', '%{actorName} requested review for this subtask [ <b>%{publicId} ] [ %{targetLanguage}<b/> ] ');
          case 'subtask_deadline_change_request_created':
            return this.$pgettext('translation', '%{actorName} requested to change the deadline');
          case 'order_transferred_to_xtrf':
            return this.$pgettext('translation', '%{adminActorName} moved order to XTRF');
          case 'order_ocr_triggered':
            return this.$pgettext('translation', 'Files OCR process was triggered');
          default:
            return '';
        }
      },
      getNotificationLogTemplate(logType) {
        switch (logType) {
          case 'update_attendee_list_with':
            return this.$gettext('Contacted %{recipientName} about this assignment');
          case 'announce_posted_job_to':
            return this.$gettext('Informed %{recipientName} that we have received their request');
          case 'announce_invitation_to':
            return this.$gettext('Contacted %{recipientName} to this assignment');
          case 'rates_changed':
            return this.$gettext('Informed %{recipientName} that job rates has been changed');
          case 'request_finish_confirmation_from':
            return this.$gettext('Informed %{recipientName} that job has been finished as requested');
          case 'announce_award_to':
            return this.$gettext('Contacted %{recipientName}, and confirmed that they got the assignment');
          case 'sms_announce_award_to':
            return this.$gettext('Contacted %{recipientName}, and confirmed that interpreter is assigned to the assignment');
          case 'announce_session_details_to':
            return this.$gettext('Contacted %{recipientName}, and shared the interpreters information');
          case 'remind_the':
            return this.$gettext('Reminded %{recipientName} about this assignment');
          case 'request_feedback_from':
            return this.$gettext('Requested feedback from %{recipientName} about this assignment');
          case 'announce_message_to':
            return this.$gettext('Informed %{recipientName} about a new chat message');
          case 'announce_cancellation_to':
            return this.$gettext('Informed %{recipientName} that this assignment is cancelled');
          case 'announce_job_changes_to':
            return this.$gettext('Informed %{recipientName} that this assignment has been changed');
          case 'announce_feedback_to':
            return this.$gettext('Informed %{recipientName} that overtime has been reported on this assignment');
          case 'request_withdraw_from_job':
            return this.$gettext('Sent a withdrawal-magic-link to the %{recipientName}');
          case 'request_cancel_job':
            return this.$gettext('Sent a cancellation-magic-link to the %{recipientName}');
          case 'announce_admin_cancellation_to':
            return this.$gettext('Sent a specific admin cancellation email to the %{recipientName}');
          case 'announce_application_to ':
            return this.$gettext('Informed %{recipientName} that overtime has been reported on this assignment');
          case 'announce_application_to':
            return this.actorIsAdmin
              ? this.$gettext('Informed %{recipientName} that %{adminActorName} applied for this assignment on behalf of %{actorName}')
              : this.$gettext('Informed %{recipientName} that %{actorName} applied for this assignment');
          case 'announce_declination_by_demander_to':
          case 'announce_declination_by_supplier_to':
            return this.actorIsAdmin
              ? this.$gettext('Informed %{recipientName} that offer has been declined by %{adminActorName} on behalf of %{actorName}')
              : this.$gettext('Informed %{recipientName} that offer has been declined by %{actorName}');
          case 'announce_withdrawal_to':
            return this.actorIsAdmin
              ? this.$gettext('Informed %{recipientName} that %{adminActorName} withdrawn from the assignment on behalf of %{actorName}')
              : this.$gettext('Informed %{recipientName} that %{actorName} withdrawn from the assignment');
          case 'not_shown':
            return this.actorIsAdmin
              ? this.$gettext('Informed %{recipientName} that %{actorName} has not showed up on behalf of %{adminActorName}')
              : this.$gettext('Informed %{recipientName} that %{actorName} has not showed up');

          // translation notification logs
          case 'announce_finished_order_to':
            return this.$pgettext('translation', 'Informed %{recipientName} that this project has finished');
          case 'announce_in_progress_order_to':
            return this.$pgettext('translation', 'Informed %{recipientName} that this project is in progress');
          case 'announce_posted_order_to':
            return this.$pgettext('translation', 'Informed %{recipientName} that we have received their request');
          case 'announce_order_quote_to':
            return this.$pgettext('translation', 'Informed %{recipientName} and proposed a quote for the project');
          case 'announce_customer_posted_order_to':
            return this.$pgettext('translation', 'Informed translations team that customer posted this project');
          case 'announce_saved_response_to':
            return this.$pgettext('translation', 'Project manager informed %{recipientName} with pre-saved email');
          case 'announce_subtask_invited_to':
            return this.$pgettext('translation', 'Contacted %{recipientName} to subtask [ <b>%{publicId} ] [ %{targetLanguage}<b/> ] ');
          case 'announce_subtask_accepted_to':
            return this.$pgettext('translation', 'Informed %{recipientName} that subtask [ <b>%{publicId} ] [ %{targetLanguage}<b/> ] has been accepted');
          case 'announce_subtask_cancelled_to':
            return this.$pgettext('translation', 'Informed %{recipientName} that subtask [ <b>%{publicId} ] [ %{targetLanguage}<b/> ] has been cancelled');
          case 'announce_subtask_changes_required_to':
            return this.$pgettext('translation', 'Informed %{recipientName} that changes are required for subtask [ <b>%{publicId} ] [ %{targetLanguage}<b/> ] ');
          case 'announce_order_moved_to_xtrf_to':
            return this.$pgettext('translation', 'Informed %{recipientName} that requested order moved to XTRF system');
          case 'announce_order_cover_letter_rejected_to':
            return this.$pgettext('translation', 'Informed %{recipientName} that provided cover letter for order has been rejected by Salita');
          case 'announce_order_cancelled_to':
            return this.$pgettext('translation', 'Informed %{recipientName} that order has been cancelled');
          default:
            return '';
        }
      }
    }
  };
</script>
